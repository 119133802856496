import { UserService } from './../../../services/user/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { InvoicesService, ListData } from 'src/app/services/invoices/invoices.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as moment from "moment";
import { filter } from 'rxjs/operators';

const STATE_ACTIVATED = {
  progress: "in-progress",
  accepted: "accepted",
  rejected: "rejected"
}

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
})
export class InvoiceListComponent implements OnInit, OnDestroy {
  ListResult =  { status: '', items: [] as ListData, error: null }
  list =  { status: '', items: [] as ListData, error: null }
  data
  StateActivated = STATE_ACTIVATED.progress
  states = STATE_ACTIVATED
  @ViewChild("DateStartInp") DateStartInp: HTMLInputElement;
  @ViewChild("DateEndInp") DateEndInp: HTMLInputElement;
  datesFilter = {
    start: null,
    end: null,
  }

  CurrencyCode = 'COP';
  subscriber$
  constructor(private invoicesService : InvoicesService , private router : Router, private userService : UserService ) { }

  ngOnInit() {
    this.getData();
    
    this.subscriber$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
       console.log('The URL changed to: ' + event['url'])
       this.getCurrency();;
    });
  }
  ngOnDestroy(): void {
    this.subscriber$?.unsubscribe();
  }

  ionViewDidEnter(){
    this.getData();
    this.getCurrency();
  }



  getData(){
    this.invoicesService.GetData().then((data)=>{
      data.subscribe((data)=>{
        this.ListResult = { status: '', items: data as ListData, error: null }
        this.data = this.ListResult
      
      const dataSort = data ? this.data?.items?.sort((a, b) => ((b["createAt"]?.toMillis()) - (a["createAt"]?.toMillis()))) : [];
      this.list.items = dataSort.filter((item) => {
        
        
        if (item.status == this.StateActivated){
          return true
        }
      })
      });
      
    console.log(this.list);
      
    })
    
  }


  filterState(state) {
    this.resetDates();
    this.StateActivated = state
    this.list.items = this.data.items.filter((item) => {
      if (item.status == this.StateActivated){
        return true
      }

    })
  }
  
  FilterDate($event, position) {
    this.datesFilter[position] = $event.target.value
    if (this.datesFilter.start) {
      
      const ItemSort = this.data ? this.data?.items?.sort((a, b) => ((b["createAt"]?.toMillis()) - (a["createAt"]?.toMillis()))) : [];
      this.list.items = ItemSort.filter((data) => {
        
        const dateDocument = moment((data["createAt"]?.toMillis())).format("YYYY-MM-DD")
        const dateEnd = this.datesFilter.end ? this.datesFilter.end : moment().format("YYYY-MM-DD")

        
         if (dateDocument >= this.datesFilter.start && dateDocument <= dateEnd) {
          if (this.StateActivated != this.states.progress) {
            if (this.StateActivated == this.states.accepted) {
              if (data.status == this.states.accepted) {
                return true;
              }
            } else {
              if (data.status == this.states.rejected) {
                return true;
              }
            }
          } else {
            if (data.status == this.states.progress) {
              return true;
            }
          }
        } 
      })
    }
  }

  resetDates() {
    let datesFilterStart = (document.getElementById("datesFilterStart") as HTMLInputElement)
    if( datesFilterStart?.value ) datesFilterStart.value = "";
    let datesFilterEnd = (document.getElementById("datesFilterEnd") as HTMLInputElement)
    if(datesFilterEnd?.value) datesFilterEnd.value = "";
  }

    public viewMore(id) {
    this.resetDates();
    this.router.navigate(['app/invoice', 'detail', id]);
  }

  getCurrency(){
    this.userService.getCurrencyCodeProgram().then((data)=>{
      console.log(data);
      
      this.CurrencyCode = data
    })
  }
}