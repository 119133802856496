import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LanguagesService } from 'src/app/services/languagesService/languages.service';
import { RemoteConfigService } from 'src/app/services/remote-config/remote-config.service';
import { isPlatform } from '@ionic/core';
import { Camera, CameraOptions } from '@ionic-native/camera';
import { ModalController, PopoverController } from '@ionic/angular';
import { CameraComponent } from '../../camera/camera.component';
import { InvoicesService } from 'src/app/services/invoices/invoices.service';
import { filter, finalize } from 'rxjs/operators';
import firebase from 'firebase';
import { StorageService } from 'src/app/services/storage/storage.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import * as moment from 'moment';
import { PopoverComponent } from '../../popover/popover.component';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'app-invoice-form-component',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss'],
})
export class InvoiceFormComponent implements OnInit, OnDestroy {
  fields = [];
  invoiceForm: FormGroup;
  fileName = '';
  name = '';
  fileNames = [];
  files = [];
  formProps;
  images = [];
  LOADER_ID = 'invoice-form';
  inputProps: {
    name: string;
    onChange: (value) => void;
    onBlur: () => void;
    onFocus: () => void;
  };
  MaxDate = moment().format('YYYY-MM-DD');

  subscriber$;
  constructor(
    public languageService: LanguagesService,
    private remoteConfig: RemoteConfigService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private invoicesService: InvoicesService,
    private storageServices: StorageService,
    private storage: AngularFireStorage,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private router: Router,
    private popoverController: PopoverController,
    private androidPermissions: AndroidPermissions,
    private userService: UserService,
    private activatedRouteSnapshot: ActivatedRoute
  ) {
    this.invoiceForm = this.formBuilder.group({});
  }

  ngOnInit() {
    // this.generateForm();
    this.userService.getUserInfo().subscribe((res: any) => {
      if (res) {
        this.name = res.name + ' ' + res.lastnames;
      }
    });
    this.subscriber$ = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        console.log('The URL changed to: ' + event['url']);
        this.invoiceForm.removeControl('products');
        this.generateForm();
        this.resetForm();
      });
  }

  ngOnDestroy() {
    this.subscriber$?.unsubscribe();
  }

  inputCameraProps = (field) => {
    const name = field.name;
    return {
      name,
      id: name,
      onChange: this.handleCameraChange(name),
      onBlur: this.handleBlur(name),
      onFocus: this.handleFocus(name),
      value: this.f[name].value,
    };
  };

  handleCameraChange = (field: string) => (value: string) => {
    this.f[field].markAsDirty();
    this.f[field].markAsTouched();
    for (let i = 0; i < value.length; i++) {
      this.uploadFile(value[i], field, true);
    }
  };

  handleBlur = (field: string) => () => {
    this.f[field].markAsDirty();
  };

  handleFocus = (field: string) => () => {
    this.f[field].markAsTouched();
  };

  get getTextsIUPageInvoices() {
    return this.languageService.textsIU.page_invoices_form;
  }

  get f() {
    return this.invoiceForm.controls;
  }

  async generateForm() {
    const fieldsData = await this.remoteConfig
      .getSettingsInvoicesConfig()
      .toPromise();
    this.fields = fieldsData.fields;
    let validations = [];
    this.fields.map(async (field) => {
      validations = [];
      field.validations?.includes('required')
        ? validations.push(Validators.required)
        : '';
      field.validations?.includes('min')
        ? validations.push(
            Validators.minLength(field.validations.split(':')[0])
          )
        : '';
      field.validations?.includes('max')
        ? validations.push(
            Validators.maxLength(field.validations.split(':')[0])
          )
        : '';
      await this.invoiceForm.addControl(
        field.name,
        field.subfields
          ? this.formBuilder.array([], validations)
          : this.formBuilder.control('', validations)
      );
      if (field.type === 'camera') {
        const formField = {
          name: field.name,
          value: field.value,
          validations: 'min:0',
        };
        this.inputProps = this.inputCameraProps(formField) as any;
      }
      if (field.type == 'array') {
        this.addField(field);
      }
    });
  }

  getArray(name) {
    const controlArray = this.invoiceForm.controls[name] as FormArray;
    return controlArray.controls;
  }

  addField(control) {
    const controlArray = this.invoiceForm.controls[control.name] as FormArray;
    if (controlArray.length == 10) {
      return this.alertService.presentAlert('El máximo de productos son 10');
    }
    let arrayForm = this.formBuilder.group({});
    let validations = [];
    control.subfields.map(async (field) => {
      validations = [];
      field.validations?.includes('required')
        ? validations.push(Validators.required)
        : '';
      field.validations?.includes('min')
        ? validations.push(
            Validators.minLength(field.validations.split(':')[0])
          )
        : '';
      field.validations?.includes('max')
        ? validations.push(
            Validators.maxLength(field.validations.split(':')[0])
          )
        : '';
      await arrayForm.addControl(
        field.name,
        this.formBuilder.control('', validations)
      );
    });
    controlArray.push(arrayForm);
  }

  deleteField(control, controlIndex: number) {
    const controlArray = this.invoiceForm.controls[control] as FormArray;
    controlArray.removeAt(controlIndex);
  }

  async uploadFile(file, field, isPhoto?) {
    const claims = await this.storageServices.get('gnx-claims');
    const ext = /[.]/.exec(file.name) ? /[^.]+$/.exec(file.name).pop() : 'png';
    const filePath = `users/${
      claims.uid
    }/invoice/${this.invoicesService.newId()}.${ext}`;
    if (file.name) {
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            const downloadURL = fileRef.getDownloadURL();
            downloadURL.subscribe((url) => {
              if (url) {
                if (isPhoto) {
                  const body = {
                    type: 'camera',
                    value: url,
                  };
                  return this.images.push(body);
                }
                const body = {
                  type: 'file',
                  value: url,
                };
                return this.files.push(body);
              }
            });
          })
        )
        .subscribe((url) => {
          if (url) {
            console.log(url);
            this.setInfoFiles(url, isPhoto, file.name);
          }
        });
    } else {
      const uploadFile = await firebase
        .storage()
        .ref()
        .child(filePath)
        .putString(file, 'data_url');
      const url = await uploadFile.ref.getDownloadURL();
      if (isPhoto) {
        console.log('aca');
        const body = {
          type: 'camera',
          value: url,
        };
        this.images.push(body);
        return this.setInfoFiles(url, true);
      }
      return this.setInfoFiles(url, false, file.name);
    }
  }
  setInfoFiles(url, isPhoto?, FileName?) {
    if (!isPhoto) {
      if (this.fileNames[this.fileNames.length - 1] == 'cargando...')
        this.fileNames.pop();

      if (url.state == 'running') {
        this.fileNames[this.fileNames?.length] = 'cargando...';
        if (this.fileNames?.length > 5) {
          this.fileNames.splice(5);
          this.files.splice(5);
        }
      } else if (url.state == 'success') {
        this.fileNames.push(FileName);
        if (this.fileNames.length > 5) {
          this.fileNames.splice(5);
          this.files.splice(5);
        }
      }
    } else {
      if (this.images.length > 5) {
        this.images.splice(5);
      }
    }
  }

  onFileSelected(event, field) {
    console.log(event.target.files);
    if (this.fileNames.length < 5) {
      const file: File = event.target.files[0];
      const files: File[] = event.target.files;
      [...files].forEach((file, i) => {
        const counter = i + 1 + this.fileNames.length;
        if (counter <= 5) {
          // this.fileNames.push(file.name)
          this.uploadFile(file, field, false);
        }
      });
    }
  }

  async openCamera() {
    if (isPlatform('hybrid')) {
      this.inputProps.onFocus();

      this.androidPermissions
        .checkPermission(this.androidPermissions.PERMISSION.CAMERA)
        .then(
          (result) => {
            console.log('Has permission?', result.hasPermission);
            if (result.hasPermission) {
              this.presentModaleCamera();
            } else {
              this.androidPermissions
                .requestPermission(this.androidPermissions.PERMISSION.CAMERA)
                .then((data) => {
                  if (data.hasPermission) {
                    this.presentModaleCamera();
                  }
                });
            }
          },
          (err) =>
            this.androidPermissions.requestPermission(
              this.androidPermissions.PERMISSION.CAMERA
            )
        );
    } else {
      this.presentModaleCamera();
    }
  }
  async presentModaleCamera() {
    const modal = await this.modalController.create({
      component: CameraComponent,
      cssClass: 'my-custom-height-modal',
      componentProps: {
        inputProps: this.inputProps,
        limitPhotos: 5,
        onClose: async (data) => {
          await modal.dismiss({ dismissed: true });
        },
      },
    });
    await modal.present();
  }
  cancel() {
    this.invoiceForm.reset();
    this.router.navigateByUrl('app/invoices');
  }

  async sendForm() {
    console.log({ ...this.invoiceForm });

    if (this.invoiceForm.valid) {
      console.clear();
      console.log(this.f['products']);
      this.f['products'].value.map((data) => (data.value = 1));
      console.log(this.f['products']);
      try {
        await this.loaderService.presentLoading(this.LOADER_ID);
        const claims = await this.storageServices.get('gnx-claims');

        const initialData = {
          invoice: {
            id: (this.f['id'].value as string).trim(),
            id_emisor: 1,
            // id_emisor: this.invoiceForm.value.id_emisor,
          },
          programId: claims.programs[0].split('/')[3],
          username: claims.username,
          fullName: this.name,
          uid: claims.uid,
          createAt: new Date(),
          timestamp: new Date(),
          status: 'in-progress',
          statusHistory: {
            'in-progress': new Date(),
          },
          photos: this.images.concat(this.files),
          total: 1,
          // total: (this.f['total'].value as string)
          //   .replace(/\./g, '')
          //   .replace(',', '.'),
          date: new Date(this.f['date'].value),
          products: (this.f['products'].value as Array<any>).map((data) => {
            const value = data.value as string;
            // ?.replace(/\./g, '')
            // ?.replace(',', '.');
            data.value = value;
            return data;
          }),
        };
        if (this.images?.length > 0) {
          if (this.fileNames?.length > 5) {
            this.images.splice(5);
            this.images.splice(5);
          }
          this.f['photo'].setValue(this.images);
        }
        if (this.files?.length > 0) {
          if (this.fileNames?.length > 5) {
            this.fileNames.splice(5);
            this.files.splice(5);
          }
          this.f['image'].setValue(this.files);
        }
        if (
          !this.f['image']?.value?.length ||
          !this.f['photo']?.value?.length
        ) {
          if (this.f['image']?.value == '' && this.f['photo']?.value == '') {
            await this.loaderService.cancelLoader(this.LOADER_ID);
            return this.alertService.presentAlert(
              'Debes agregar una foto o un archivo'
            );
          }
        }
        const fixedFields = [
          'id_emisor',
          'date',
          'total',
          'photo',
          'image',
          'id',
          'products',
        ];
        let fields = [];
        Object.keys(this.invoiceForm.controls).forEach((key) => {
          if (!fixedFields.includes(key)) {
            fields.push({
              name: key,
              value: this.f[key]?.value,
            });
            this.invoiceForm.removeControl(key);
          }
        });
        let data;
        if (fields?.length > 0) {
          data = { fields: fields, ...initialData };
        } else {
          data = { ...initialData };
        }
        const id = this.invoicesService.saveInvoice(data, claims.uid);
        this.resetForm();
        //this.alertService.presentAlert("Información guardada exitosamente");
        this.router.navigate(['app/invoice', 'request-received', id]);
        await this.loaderService.cancelLoader(this.LOADER_ID);
        this.loaderService.cancelLoader(this.LOADER_ID);
      } catch (error) {
        await this.loaderService.cancelLoader(this.LOADER_ID);
        this.alertService.showErrorAlert(`Error, ${error}`);
      }
    } else {
      this.alertService.presentAlert('Debes diligenciar los campos requeridos');
    }
  }

  resetForm() {
    this.invoiceForm.reset();
    this.fileName = '';
    this.images = [];
    this.files = [];
    this.fileNames = [];
    this.f['image']?.setValue('');
    this.f['photo']?.setValue('');
  }

  async presentPopover(ev: any, field) {
    console.log(field.valueHelp);

    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: ev,
      translucent: true,
      componentProps: {
        message: field.valueHelp,
      },
    });
    return await popover.present();
  }
}
