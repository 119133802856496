import { RouterService } from 'src/app/services/router/router.service';
import { PushNotificationsManagerService, UserNotification } from './push-notifications-manager.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  Plugins,
  PushNotification
} from '@capacitor/core';

import * as moment from "moment";

const { PushNotifications } = Plugins;
const Types = {
  recognition_granted: {
    icon: "../../../assets/img/notifications/reconocimiento.svg",
    router: "/app/recognitions"
  },
  invoices: {
    icon: "../../../assets/img/notifications/invoice.svg",
    router: "/app/invoice/detail/",
  },
  challenges: {
    icon: "../../../assets/img/notifications/challenge.svg",
    router: "/app/challenges",
  },
  exhibitions: {
    icon: "../../../assets/img/notifications/exhibitions.svg",
    router: "/app/exhibitions/detail/",
  },
  order_state_changed: {
    icon: "../../../assets/img/notifications/order.svg",
    router: "/app/profile/orders/",
  },
  points_expiry: {
    icon: "../../../assets/img/notifications/points.svg",
    router: "/app/profile/history-points"
  },
  points_credit: {
    icon: "../../../assets/img/notifications/points.svg",
    router: "/app/profile/history-points"
  },
  points_exchange: {
    icon: "../../../assets/img/notifications/points.svg",
    router: "/app/profile/history-points"
  },
  points_adjustment: {
    icon: "../../../assets/img/notifications/points.svg",
    router: "/app/profile/history-points"
  },
  points_refund: {
    icon: "../../../assets/img/notifications/points.svg",
    router: "/app/profile/history-points"
  }

}
@Component({
  selector: 'app-push-notifications-manager',
  templateUrl: './push-notifications-manager.component.html',
  styleUrls: ['./push-notifications-manager.component.scss'],
})



export class PushNotificationsManagerComponent implements OnInit {
  dataNotificaciones: UserNotification[];
  listenerDataWithoutSubscribe 
  @Output() onBack: EventEmitter<any> = new EventEmitter();
  @Output() numberNotifications: EventEmitter<any> = new EventEmitter();
  loader = true;
  constructor(
    private pushNotificationsManagerService: PushNotificationsManagerService,
    private router: RouterService
  ) { }

  async ngOnInit() {
  this.pushNotificationsManagerService.dataNotifications$.subscribe((data)=>{
    this.getNotifications(data);
  })
  this.listenerDataWithoutSubscribe = setInterval(() => {
    this.getNotifications();
  }, 10000);
    
  }

  getNotifications(data?) {
    console.log('getNotifications data: ', data);
    this.loader = true;
    if(data){
      this.dataNotificaciones = data
      this.transformDate();
      this.loader = false;
     // this.dataNotificaciones.forEach(element => { element.image = Types[element.type]?.icon });
    } else {
    this.pushNotificationsManagerService.getNotifications()
      .then((data: UserNotification[]) => {
        if (data) {
          clearInterval(this.listenerDataWithoutSubscribe)
          this.dataNotificaciones = data
          this.transformDate();
          this.loader = false;
         
        } else {
          this.loader = false;
          this.dataNotificaciones = null;

        }
        console.log('data Notificaciones', data);
        //this.numberNotifications.emit(data.filter((element)=>{return element.viewed}))
      }).catch((err)=>{
        this.loader = false;
        this.dataNotificaciones = null;
      })
    }
  }

  transformDate(){
    const TODAY = moment().clone().startOf('day');
    const YESTERDAY = moment().clone().subtract(1, 'days').startOf('day');
    this.dataNotificaciones.forEach(element => { element.date 
    if( moment(element.createdAt).isSame(TODAY, "d")){
    element.date = 'Hoy'
    }else if( moment(element.createdAt).isSame(YESTERDAY, 'd')){
      element.date = 'Ayer'
    }else{
      element.date = moment(element.createdAt).format('DD/MM/YY')
    }
    });/* createdAt = Types[element.type]?.icon */
  }

  goToRoute(notification: UserNotification, index) {
    this.onBack.emit()
    notification.viewed = true 
    this.pushNotificationsManagerService.viewNotifications(notification).then((data:any)=>{
      if(data.updated){
        this.pushNotificationsManagerService.getNotifications();
      }
     
     
    })
    if (Types[notification.type]?.router) {
      const route = notification.entityId ?
        Types[notification.type]?.router + notification.entityId : Types[notification.type]?.router

      this.router.redirect(route)
    }
  }
  close(){
    this.onBack.emit()
  }

}
