import { Injectable } from "@angular/core";
import { forkJoin } from "rxjs";
import { ILang } from "src/app/interfaces/ILangs";
import { IRemoteConfig } from "src/app/interfaces/IRemoteConfig";
//Services
import { RemoteConfigService } from "../remote-config/remote-config.service";
import { UniteObjectsService } from "../uniteObjects/unite-objects.service";

@Injectable({
  providedIn: "root",
})
export class LanguagesService {
  language: string = "es"; //TODO: falta enlazar esta varible a al cambiar idioma
  textsIU: ILang;
  constructor(
    private remoteConfigService: RemoteConfigService,
    private uniteObjectsService: UniteObjectsService
  ) {
    // this.language = this.remoteConfigService.languageDefault;

    //this.loadLanguage();
  }

  async loadLanguage(lang: string) {
    forkJoin(
      this.remoteConfigService.getLanguage('es'), // textsIU
      this.remoteConfigService.getRoutes(), // routes
      this.remoteConfigService.getSettingsMenu(), // settings menu
      this.remoteConfigService.getOnBoarding(), // data onboarding
      this.remoteConfigService.getSettingsPageHome(), // settings page home
      this.remoteConfigService.getSetingsTabs(), // settings tabs
      this.remoteConfigService.getLoginChat(),
      this.remoteConfigService.getSettingsCategoryContent(), // settings category content
      this.remoteConfigService.getSettingsMenuWeb() // settings menu
    ).subscribe(
      (response: IRemoteConfig) => {
        this.uniteObjectsService.addParameters(response);
        //console.log("+lx,",response);
        this.textsIU = response[0];
        //console.log("+lg,",this.textsIU );
      },
      (error) =>
        console.log("error al cargar configuraciones de remote config", error)
    );
  }

  changeLanguage(language: string) {
    // --->>> active next line
    this.language = language;
    // this.language = language === "es" ? "es" : "en";
    switch (this.language) {
      case "es":
        this.loadLanguage("es");
        break;
      case "en":
        this.loadLanguage("en");
        break;
      default:
        break;
    }
  }
  get loadedLanguage() {
    if (this.textsIU) return true;
    return false;
  }

  check(setting, name) {
    // console.log(setting, name);
    return setting.active === true && setting.name === name;
  }
  sortSettings(settings) {
    Object.keys(settings).map((label) => {
      if (Array.isArray(label)) {
        settings[label].sort((a, b) => a.order - b.order);
      }
    });
    return settings;
  }
  // //   // initSettings() {
  // //   //   return new Promise((resolve) => {
  // //   //     this.http.get("assets/settings.json").subscribe((res) => {
  // //   //       this.settings = this.sortSettings(res);
  // //   //       resolve(true);
  // //   //     });
  // //   //   });
  // //   // }

  // //   // registerMethods() {
  // //   //   return new Promise((resolve) => {
  // //   //     this.http.get("assets/settings.json").subscribe((res: any) => {
  // //   //       resolve(res.register_methods);
  // //   //     });
  // //   //   });
  // //   // }
  // // }
}
