import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PointsService } from 'src/app/services/points/points.service';
import { LanguagesService } from 'src/app/services/languagesService/languages.service';
import * as moment from 'moment';

const STATE_ACTIVATED = {
  All: 'all',
  Used: 'used',
  Won: 'won',
};

@Component({
  selector: 'app-history-points-component',
  templateUrl: './history-points.component.html',
  styleUrls: ['./history-points.component.scss'],
})
export class HistoryPointsComponent implements OnInit {
  date: any;
  loading: boolean = false;
  errorFlag: boolean = false;
  errorMessage: string;
  tab: number;
  data: any;
  headerTabs: string[] = [];
  localeStrings: any;
  pointsSort: any[];
  message_error: string;
  pointsName: String = 'puntos';

  infoYear: any = 0;
  infoMonth: any;

  StateActivated = STATE_ACTIVATED.All;
  states = STATE_ACTIVATED;
  // @Input() pointsName: string = "pointz";
  @ViewChild('DateStartInp') DateStartInp: HTMLInputElement;
  @ViewChild('DateEndInp') DateEndInp: HTMLInputElement;
  datesFilter = {
    start: null,
    end: null,
  };

  constructor(
    private pointsService: PointsService,
    private languageService: LanguagesService
  ) {}

  ngOnInit() {
    this.getData();
  }

  ionViewDidEnter() {
    this.resetDates();
  }

  async getData() {
    console.log('wc history');
    this.errorMessage = this.getTextsIUPageHistoryPoints.message_error;
    const { base_url, api_key, token, accountId } =
      await this.pointsService.historyCredentials();
    if (base_url === null) {
      this.loading = false;
      this.errorFlag = true;
      this.errorMessage = this.getTextsIUPageHistoryPoints.message_error;
      return false;
    }
    if (base_url && api_key && token) {
      return this.getHistoryPoints(accountId);
    }
    return this.setErrorMessage();
  }

  setErrorMessage = () => {
    this.errorFlag = true;
    this.errorMessage = this.message_error;
  };
  async getHistoryPoints(accountId) {
    this.loading = true;
    this.errorFlag = false;

    try {
      this.data = await this.pointsService.getUserHistoryPoints(accountId);
      this.pointsSort = this.data
        ? this.data.data.sort(
            (a, b) =>
              new Date(b['createdAt']).getTime() -
              new Date(a['createdAt']).getTime()
          )
        : [];
      console.log('pointsSort 1 ==', this.pointsSort);

      //cambio de descripcion de ingles a es
      const descriEs = `Retiro de ${this.pointsName}`;
      this.pointsSort.map(function (dato) {
        if (dato.type === 'exchange') {
          dato.description = descriEs;
        }
        return dato;
      });

      //console.log("pointsSort 2 ==", this.pointsSort);

      this.getInfoPoints(this.pointsSort);
      this.loading = false;
    } catch (err) {
      this.errorFlag = true;
      this.errorMessage = 'Error';
      this.loading = false;
    }
  }

  getInfoPoints(data) {
    let date = new Date();
    var firstDay = new Date(date.getFullYear(), 0, 1);
    var lastDayMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var test = 0;
    data.forEach(function (value) {
      if (date > firstDay && date < lastDayMonth) {
        test = test + value.points;
      }
    });
  }

  filterState(state) {
    this.resetDates();
    this.StateActivated = state;
    const POINTS_SORT = this.data
      ? this.data.data.sort(
          (a, b) =>
            new Date(b['createdAt']).getTime() -
            new Date(a['createdAt']).getTime()
        )
      : [];
    if (state == this.states.All) {
      this.pointsSort = POINTS_SORT;
      return;
    }
    this.pointsSort = POINTS_SORT.filter((data) => {
      if (state == this.states.Won && data.points > 0) {
        return true;
      }
      if (state == this.states.Used && data.points < 0) {
        return true;
      }
    });
  }

  FilterDate($event, position) {
    this.datesFilter[position] = $event.target.value;
    if (this.datesFilter.start) {
      const POINTS_SORT = this.data
        ? this.data.data.sort(
            (a, b) =>
              new Date(b['createdAt']).getTime() -
              new Date(a['createdAt']).getTime()
          )
        : [];
      this.pointsSort = POINTS_SORT.filter((data) => {
        const dateLocalPoint = moment(data.createdAt).toLocaleString();
        const datePoint = moment(dateLocalPoint).format('YYYY-MM-DD');
        const dateEnd = this.datesFilter.end
          ? this.datesFilter.end
          : moment().format('YYYY-MM-DD');
        if (datePoint >= this.datesFilter.start && datePoint <= dateEnd) {
          if (this.StateActivated != this.states.All) {
            if (this.StateActivated == this.states.Won) {
              if (data.points > 0) {
                return true;
              }
            } else {
              if (data.points < 0) {
                return true;
              }
            }
          } else {
            return true;
          }
        }
      });
    }
  }

  resetDates() {
    let datesFilterStart = document.getElementById(
      'datesFilterStart'
    ) as HTMLInputElement;
    if (datesFilterStart?.value) datesFilterStart.value = '';
    let datesFilterEnd = document.getElementById(
      'datesFilterEnd'
    ) as HTMLInputElement;
    if (datesFilterEnd?.value) datesFilterEnd.value = '';
  }

  get getTextsIUPageHistoryPoints() {
    return this.languageService.textsIU.page_history_points;
  }
}
