import { Subscription } from 'rxjs';
import { UtilsService } from "src/app/services/utils/utils.service";
import { ModalController, NavParams } from "@ionic/angular";
import { AlertService } from "src/app/services/alert/alert.service";
import { Component, OnInit } from "@angular/core";
import { LanguagesService } from "src/app/services/languagesService/languages.service";
import { RemoteConfigService } from "src/app/services/remote-config/remote-config.service";
import { RouterService } from "src/app/services/router/router.service";
import { StorageService } from "src/app/services/storage/storage.service";
import { TagsService } from "src/app/services/tags/tags.service";
import { RedemptionService } from "src/app/services/redemption/redemption.service";
import { ActivatedRoute } from '@angular/router';
import { ElasticService } from 'src/app/services/elastic/elastic.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import { CartProductComponent } from '../cart-product/cart-product.component';

@Component({
  selector: "app-catalogue-detail-component",
  templateUrl: "./catalogue-detail.component.html",
  styleUrls: ["./catalogue-detail.component.scss"],
})
export class CatalogueDetailComponent implements OnInit {
  productInfo;
  enableReedem: boolean = false;
  id: string = "";
  userPoints: number;
  productPrice = 0;
  exitsActiveAddress$: Subscription
  quantity = 1;
  disableQuantity = false;
  constructor(
    private params: NavParams,
    private route: ActivatedRoute,
    private router: RouterService,
    public languageService: LanguagesService,
    public remoteConfig: RemoteConfigService,
    public utilsService: UtilsService,
    private tagsService: TagsService,
    public alertService: AlertService,
    private redemptionService: RedemptionService,
    private elasticService: ElasticService,
    private modalController: ModalController,
    private storageService: StorageService,
    private shoppingCartService: ShoppingCartService
  ) {
    this.enableReedem = this.remoteConfig.enableReedem();
    this.id = this.params.get("productId");
  }

  ngOnInit() {
    this.checkParam();
    this.utilsService.getUserPoints().then((points: any) => {
      this.userPoints = points;
       if (this.remoteConfig.enableReedem())
         if (points > 0) {
           this.enableReedem = true;
         } else {
           this.enableReedem = false;
         }
    });
    this.id = this.params.get("productId");
  }
  ngOnDestroy(){
    this.UnsubscribeExitsActiveAddress();
  }
  ionViewDidLeave(){
    this.UnsubscribeExitsActiveAddress();
  }
  UnsubscribeExitsActiveAddress(){
    if(this.exitsActiveAddress$){
      this.exitsActiveAddress$.unsubscribe();
    this.exitsActiveAddress$ = null; 
    }
  }
  async checkRedemption(product) {
    const redemptionInfo = await this.redemptionService.getProgramInfo();
    if (redemptionInfo["redemption_limit"]?.enabled) {

      if (redemptionInfo["redemption_limit"]?.min_date && redemptionInfo["redemption_limit"]?.max_date) {
        if (redemptionInfo["redemption_limit"]?.min_date.length > 0 && redemptionInfo["redemption_limit"]?.max_date.length > 0) {
          const now = new Date();
          const minDate = new Date(redemptionInfo["redemption_limit"]?.min_date);
          const maxDate = new Date(redemptionInfo["redemption_limit"]?.max_date);
          if (now < minDate || now > maxDate) {
            if(!this.redemptionService.IsOpen) return this.redemptionService.openModal('catalogue now < minDate || now > maxDate');
          }
        }
      }

      if (redemptionInfo["redemption_limit"]?.min_user_points_limit) {
        if (this.productPrice < redemptionInfo["redemption_limit"]?.min_user_points_limit) {
          if(!this.redemptionService.IsOpen) return this.redemptionService.openModal('catalogue - this.productPrice < redemptionInfo["redemption_limit"]?.min_user_points_limit');
        }
      }

      this.productDetailSelected(product);

    } else {
      this.productDetailSelected(product);
    }
  }

  async checkParam() {
    const param = this.route.snapshot.paramMap.get("id");
   /*  this.tagsService.setEventTag(
      "rewards_detail_page",
      "rewards_category_detail_screen_viewed"
    ); */
    this.id = param || this.id;
    const product = await this.elasticService.getProductById(this.id);
    this.productInfo = product.results[0];
    await this.shoppingCartService.getCart().then(async (data: any) => {
      let products = data.items;
      if(products.length > 0){
        const exist = products.filter(x => x.id.raw == this.productInfo.id.raw);
        if(exist.length > 0){
          this.disableQuantity = true;
        }
      }
    })
  }

  addProductCart(product){
    this.shoppingCartService.createCart(product).then(async (data) => {
      console.log('data', data)
      const modal = await this.modalController.create({
        component: CartProductComponent,
        id: "shopping-cart-modal",
        componentProps: {
          product: product,
          onClose: async (data) => {
            await modal.dismiss({ dismissed: true });
          }
        },
        backdropDismiss:false,
        swipeToClose:false
      });
      modal.present();
    })
  }

  async productDetailSelected(event: any) {
    const product = event;
    product.quantity = this.quantity;
    this.productPrice = event.pointz.raw;

    this.shoppingCartService.getCart().then((res: any) => {
      let products: any = Object.values(res.items);
      if(products){
        const exist = products.filter(x => x.sku == product.sku.raw);
        if(exist.length > 0){
          this.alertService.presentAlert("Este producto ya se encuentra agregado, dirigete al carrito de compras.");
          return;
        }
      }
      this.addProductCart(product);
    }).catch(err => {
      if(err.error.code == "NOT_FOUND"){
        this.addProductCart(product);
      }
    });
    
  }

  get getTextsIUPageCatalogueDetails() {
    return this.languageService.textsIU.page_catalogue_detail;
  }
  get getTextIUCurrency() {
    return this.languageService.textsIU.currency_data;
  }

  addProduct(){
    this.quantity = this.quantity + 1;
  }

  removeProduct(){
    if(this.quantity > 0){
      this.quantity = this.quantity - 1;
    }
  }

}
