import { Injectable, Injector } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from '../storage/storage.service';
import * as moment from 'moment';
import { AlertService } from '../alert/alert.service';
import { ErrorResponseText } from './ErrorResponseText';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService {
  constructor(
    private firestore: AngularFirestore,
    private storageServices: StorageService,
    private injector: Injector
  ) {}

  handleError(error: Error | HttpErrorResponse) {
    const date = new Date();
    (async () => {
      let claims;
      try {
        claims = await this.storageServices.get('gnx-claims');
      } catch (err) {
        console.error('Error fetching claims:', err);
        claims = null; // Set a default value if fetching claims fails
      }

      const ERROR_REFINED: any = { ...error };
      ERROR_REFINED['date'] = date;
      ERROR_REFINED['claims'] = claims;
      ERROR_REFINED['type'] = 'Client Error';

      if (error instanceof HttpErrorResponse) {
        // Server Error
        console.error('HttpErrorResponse handleError Server -> ', date, error);
        ERROR_REFINED['type'] = 'Server Error';
        delete ERROR_REFINED['headers'];

        if (Object.keys(ErrorResponseText).includes(error.status.toString())) {
          this.alertError(`${ErrorResponseText[error.status]} - ${error.url}`);
        }
      } else {
        // Client Error
        delete ERROR_REFINED['promise'];
        delete ERROR_REFINED['task'];
        delete ERROR_REFINED['zone'];
        ERROR_REFINED['stask'] = error.stack;
        console.error('HttpErrorResponse handleError Client -> ', date, error);
        return;
      }

      // Always log errors
      try {
        await this.setLogger(ERROR_REFINED);
      } catch (loggingError) {
        console.error('Error logging error:', loggingError);
      }
    })();
  }

  async setLogger(data) {
    console.debug('setLogger data -> ', data);
    try {
      const docName = `${moment(data.date).format('YYYYMMDD')}`;
      const ref = await this.firestore
        .collection('log-errors')
        .doc(docName)
        .collection(`${data.date.valueOf()}`)
        .doc(`${data.date.valueOf()}`)
        .set(data);
    } catch (firestoreError) {
      console.error('Error writing to Firestore:', firestoreError);
    }
  }

  alertError(message: string) {
    const alertService = this.injector.get(AlertService);
    (async () => {
      try {
        await alertService.presentOrderStatus(
          message,
          'close-circle-outline',
          'gnx-icon-order-status-error'
        );
      } catch (alertError) {
        console.error('Error presenting alert:', alertError);
      }
    })();
  }
}
