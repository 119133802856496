import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import firebase from 'firebase/app';
import { StorageService } from "../storage/storage.service";
import { environment } from "../../../../src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { ModalAlertsComponent } from 'src/app/components/modal-alerts/modal-alerts.component';
import { ModalController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class CanExchangeService {

  uriApiOrder: string = environment.gluky.ordersApiBasePatch;
  api_key: any;

  constructor(
    private http: HttpClient,
    private storageServices: StorageService,
    private modalController: ModalController,
    private authService:AuthService) { }

 
  async getCanExchange() {
    const claims = await this.storageServices.get("gnx-claims");

    return new Promise((resolve, reject) =>
      this.http
        .get(
          `${this.uriApiOrder}/user-orders/${claims.uid}/can-exchange`
        )
        .subscribe(
          async (res) => {
            console.log("can-exchange == ",res);
            
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
    );
  }

  async openModal(router?) {
    console.log('open from ', router);
      const modal = await this.modalController.create({
        component: ModalAlertsComponent,
        id: "redemption-modal",
        componentProps: {
          exchange: true,
          //fromCatalogueDetail: true,
          onClose: async (data) => {
            await modal.dismiss({ dismissed: true });
          }
        },
        cssClass:'my-custom-height-modal-redemption',
        backdropDismiss:false,
        swipeToClose:false
      });

      modal.onDidDismiss().then(() => {

        this.closeAllModalsRedemption();
      })
      return await modal.present().then(() => {     
        const el =  document.getElementsByTagName('ion-modal')
        for (let i = 0; i < el.length; i++) {
          const element = el[i];
          if (element.id == 'redemption-modal') {
            element.style.setProperty('--height', '40vh'); 
          } 
        }
      });
  }

  closeAllModalsRedemption(){
    const alerts = document.getElementsByTagName('ion-modal')
    for (let i = 0; i < alerts.length; i++) {
      const element = alerts[i];
      if (element.id == 'redemption-modal') {
         element.remove();
      } 
      element.dismiss();
      
    }
  }
  

}
