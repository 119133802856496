import { Injectable } from "@angular/core";
import { Plugins } from "@capacitor/core";
import jwt_decode from "jwt-decode";
const { Storage } = Plugins;

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor() {
    Storage.addListener("change", (change) => {
      console.log('Storage.addListener("change")', change);
    });
    Storage.addListener('clear', (clear) => {
      console.log('Storage.addListener("clear")', clear);
    });
  }
  async set(key: string, value: any): Promise<void> {
    if(key === 'gnx-token') {
      const decodedToken: any = jwt_decode(value);
      await Storage.set({key: 'gnx-claims', value: JSON.stringify(decodedToken.gluky),});
    }

    await Storage.set({
      key: key,
      value: JSON.stringify(value),
    });
  }

  async get(key: string): Promise<any> {
    const item = await Storage.get({ key: key });
    if(item && item.value !== "undefined"){
      return JSON.parse(item.value);
    } else {
      return null;
    }
  }

  async remove(key: string): Promise<void> {
    await Storage.remove({
      key: key,
    });
  }

  async removeAll() {
    await Storage.clear();
  }
}
