import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { StorageService } from "../storage/storage.service";
import { AuthService } from "../auth/auth.service";
import { UtilsService } from "src/app/services/utils/utils.service";
import moment from "moment";
import { RouterService } from 'src/app/services/router/router.service';

@Injectable({
  providedIn: "root",
})
export class GnxGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    private utilsService: UtilsService,
    private routerService: RouterService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Promise<boolean> {
    const gnxToken = localStorage.getItem("gnx-token");
    console.log("gnxToken: respuesta->",gnxToken);
    return this.storageService.get("gnx-token")
      .then(async (res) => {
        if (res == null) {
          this.routerService.redirect("login");
          return Promise.resolve(true);
        }

        if (res != null) {
          console.log("res token guard", res);
          const data = this.utilsService.parseJwt(res);
          const actualDate = moment().unix();
          if (data?.exp) {
            const tokenDateExp = data?.exp <= actualDate;
            if (tokenDateExp) {
              this.authService.refreshTokenAndSaveOnLocalStorage();
            }
          }
        }

        if (res) {
          var user = this.parseJwt(res);
          //console.log("user", user);
          if (user && user['iat']) {
            // var minutesToAdd=0.2;
            // const expiryTime = new Date(user['iat'] * 1000);
            // var futureDate = new Date(expiryTime.getTime() + minutesToAdd*60000);
            // var currentDate = new Date();
            //console.log("expiryTime", expiryTime, new Date(expiryTime))
            //console.log("futureDate", futureDate, new Date(futureDate))
            // if (currentDate.valueOf() > futureDate.valueOf()){
            //   await this.authService.logout();
            //   return false;
            // }
          }
          return true;
        } else {
          console.log('no res token GnxGuard', res);
        }


      }).catch(err => {
        console.log('catch(err GnxGuard', err);
        this.storageService.removeAll();
        return false;
      })
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };
}