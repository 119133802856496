import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RouterService } from '../router/router.service';
import { StorageService } from '../storage/storage.service';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { NavController } from '@ionic/angular';
import { fromEvent } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    private router: Router,
    private navCtrl: NavController,
    public authService: AuthService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return await this.checkAuth();
  }

  private async checkAuth() {
    if (window.innerWidth > 780) {
      this.routeToLogin();
    }

    const validationToken = await this.storageService.get('gnx-token');
    console.log(validationToken);
    if (validationToken) {
      const parseJson = this.parseJwt(validationToken);
      console.log('parseJson', parseJson);
      if (parseJson && parseJson.gluky && parseJson.gluky.uid) {
        console.log('parseJson.gluky.uid', parseJson.gluky.uid);
        this.routeToLogin();
      }
    }
    return true;
  }

  private routeToLogin(): boolean {
    try {
      this.router
        .navigate(['/login'])
        .then((res) => {
          localStorage.clear();
          console.log('NAVEGO', res);
        })
        .catch((error) => {
          console.log('cath router', error);
        });
      return false;
    } catch (error) {
      console.log('routeToLogin', error);
    }
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }
}
