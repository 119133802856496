import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
} from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import {
  catchError,
  delay,
  mergeMap,
  retryWhen,
  switchMap,
  tap,
} from 'rxjs/operators';
import { from, of, throwError, Observable, timer } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { RouterService } from '../router/router.service';

const CODES_TO_RETRY = [500, 502, 503, 504]; // Códigos de estado HTTP a los que se debe intentar de nuevo
const RETRIES = 2; // Número predeterminado de reintentos
const RETRY_WAIT_MILLI_SECONDS = 6000; // Retraso predeterminado entre reintentos en milisegundos

@Injectable({
  providedIn: 'root',
})
export class ServerHttpInterceptor implements HttpInterceptor {
  retryDelay = RETRY_WAIT_MILLI_SECONDS;
  retryMaxAttempts = RETRIES;

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private routerService: RouterService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get('X-Skip-Interceptor') === '') {
      return next.handle(request).pipe(
        this.retryAfterDelay(),
        catchError((error) => this.handleError(error))
      );
    }

    return from(this.authService.refreshToken()).pipe(
      switchMap((token) => {
        let headers = {
          'Content-Type': 'application/json',
        };

        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        if (request.headers.get('X-Skip-Interceptor') !== '') {
          request = request.clone({
            setParams: {
              key: this.authService.apiKey,
            },
          });
        }

        request = request.clone({
          setHeaders: headers,
        });

        return next.handle(request).pipe(
          this.retryAfterDelay(),
          catchError((error) => this.handleError(error))
        ) as Observable<HttpEvent<any>>;
      }),
      catchError((error) => this.handleError(error))
    ) as Observable<HttpEvent<any>>;
  }

  retryAfterDelay(): any {
    return retryWhen((errors) => {
      return errors.pipe(
        mergeMap((err, count) => {
          const retryAttempt = count + 1;
          if (
            retryAttempt > this.retryMaxAttempts ||
            !CODES_TO_RETRY.includes(err.status)
          ) {
            return throwError(err);
          }
          console.log(`Retrying ${err.url}. Retry attempt ${retryAttempt}.`);
          return timer(this.retryDelay);
        })
      );
    });
  }

  private handleRetry(error: Observable<any>): Observable<any> {
    return error.pipe(
      mergeMap((err, index) => {
        if (
          index < this.retryMaxAttempts &&
          CODES_TO_RETRY.includes(err.status)
        ) {
          return of(err).pipe(delay(this.retryDelay));
        }
        return throwError(err);
      })
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('HTTP Error:', error.message);
    if (
      error.status === 403 &&
      error.error &&
      error.error.code === 'ACCESS_DENIED'
    ) {
      this.handleAccessDenied();
    }
    return throwError(error);
  }

  private handleAccessDenied(): void {
    localStorage.clear();
    this.storageService.removeAll();
    this.routerService.navigate('login', { replaceUrl: true });
  }
}
